import actions from './actions';

const initState = {
  usuario: 'Usuário não Logado',
  email: '',
  photo: '',
  checkEmail: false
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.LOAD_USER:
      return {
        ...state,
        usuario: action.payload.user,
        email: action.payload.email,
        photo: action.payload.photo,
        checkEmail: action.payload.checkEmail,
      };
    case actions.GIT_SUCCESS_RESULT:
      return {
        ...state,
        loading: false,
        error: false,
        result: action.result,
        total_count: action.total_count,
        page: action.page
      };
    case actions.GIT_ERROR_RESULT:
      return {
        ...state,
        loading: false,
        error: false,
        result: []
      };
    default:
      return state;
  }
}