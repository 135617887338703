export default {
  apiUrl: ''
};
const siteConfig = {
  siteName: 'AcineJovem',
  siteNameMini: 'A.J.',
  siteIcon: 'ion-flash',
  linkIcon: './image/favicon.png',
  footerText: '©2022 Criado por NFJ Sistemas - V1.01.01',
  linkFooterText: 'https://www.nfj.com.br',
  addressFull: '',
  city: '',
  email: '',
  logo_171_41:'/assets/img/logo.png',
  logo:'/assets/img/logo.png',
  sobre_nos:'',
  linkedin:'',
  facebook:'',
  instagram: '',
  youtube:''
};
const firebaseConfig = {
  apiKey: "AIzaSyC2e5Gqa6GHy0GqAaJGuXRrIbFGrsEXNLE",
  authDomain: "acinejovem-9e3d6.firebaseapp.com",
  databaseURL: "https://acinejovem-9e3d6.firebaseio.com",
  projectId: "acinejovem-9e3d6",
  storageBucket: "acinejovem-9e3d6.appspot.com",
  messagingSenderId: "313512717668",
  appId: "1:313512717668:web:814ecb21a1fe86d26b1e71",
  measurementId: "G-PHBVW65G4S"
};

export{
  siteConfig,
  firebaseConfig
}


