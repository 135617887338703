const DOCUMENT = 'CREDENCIAL_';

export const actions = {
  LOAD: DOCUMENT+'LOAD',
  LOAD_SUCCESS: DOCUMENT+'LOAD_SUCCESS',
  LOAD_ERROR: DOCUMENT+'LOAD_ERROR',

  RESET: DOCUMENT+'RESET',
  REFRESH: DOCUMENT+'REFRESH',

  LOAD_ASSINATURA: DOCUMENT+'LOAD_ASSINATURA',
  LOAD_ASSINATURA_SUCCESS: DOCUMENT+'LOAD_ASSINATURA_SUCCESS',

  LOAD_PRODUTOS: DOCUMENT+'LOAD_PRODUTOS',
  LOAD_PRODUTOS_SUCCESS: DOCUMENT+'LOAD_PRODUTOS_SUCCESS',

  LOAD_FROM_FIRESTORE: DOCUMENT + 'LOAD_FROM_FIRESTORE',
  LOAD_FROM_FIRESTORE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_SUCCESS',
  LOAD_FROM_FIRESTORE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_ERROR',
  
  SAVE_INTO_FIRESTORE: DOCUMENT + 'SAVE_INTO_FIRESTORE',
  SAVE_INTO_FIRESTORE_ERROR: DOCUMENT + 'SAVE_INTO_FIRESTORE_ERROR',

  FIRESTORE_UPDATE: DOCUMENT + 'FIRESTORE_UPDATE',
  
  loadFromFireStore: () => {
    return { type: actions.LOAD_FROM_FIRESTORE};
  },

  loadAssinatura: (email) => {
    return { type: actions.LOAD_ASSINATURA,
      payload: { email }
    };
  },

  loadFromFireStoreSuccess: data => ({
    type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
    payload: { data },
  }),

  loadFromFireStoreError: error => ({
    type: actions.LOAD_FROM_FIRESTORE_ERROR,
    payload: { error },
  }),

  loadCredencial: (payload) => ({
    type: actions.LOAD, payload
  }),

  saveIntoFireStore: (data, actionName = 'insert') => ({
    type: actions.SAVE_INTO_FIRESTORE,
    payload: { data, actionName },
  }),

};
export default actions;
