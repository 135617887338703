import actions from "./actions";

const initState = { isLoggedIn:false, isCheking:false, isRedirect:false, routeToRedirect:'/', idToken: null, uid:null, displayName:null, photoURL:null,email:null, emailVerified:null,sendConfirmation:true,emailReset:false,createUser:false,msg:""};
//isCheking mean that is cbeking login
export default function authReducer(state = initState, action) {
  let direct = false;
  let route='';
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        uid: action.payload.uid,
        displayName: action.payload.displayName,
        photoURL: action.payload.photoURL,
        email: action.payload.email,
        emailVerified: action.payload.emailVerified,
        idToken: action.payload.ra,
        isCheking:false
      };
    case actions.LOGOUT:
      return {...initState,isCheking:false};
   case actions.EMAIL_VERIFICATION_SAGA:
        return {...state, sendConfirmation:true};  
   case actions.EMAIL_RESET:
          return {...state};
   case actions.EMAIL_RESET_OK:
          return {...state, emailReset:true};         
    case actions.CHANGE_LOGIN_SUCCESS:
      if(action.page!=''){
        direct=true
        route=action.page
        console.log(direct, route)
      }
      return {...state, email:action.payload.email, isRedirect:direct,routeToRedirect:route,isCheking:false};             
    case actions.GET_USER:
        return [...state, action.user];
    case actions.ROUTE_TO_REDIRECT:
      return [...state, action.route];    
    case actions.CREATE_USER_SUCCESS:
          return {...state, createUser:true, email: action.email,name: action.name};
    case actions.CREATE_USER_ERROR:
            return {...state, createUserError:true, msg: action.payload.message};      
    case actions.CHECK_AUTHORIZATION:
      
      if(action.payload.page!='' && action.payload.page!='/'){
        direct=true
        route=action.payload.page

        return {
          ...state, isCheking:true, isRedirect:direct,routeToRedirect:route
        }
      }else{
        return {
          ...state, isCheking:true
        }
      }
      
    case actions.NO_LOGGED:
      return {
        ...state, isCheking:false
      }

    case actions.CHECK_AUTHORIZATION_ERROR:
      return {
        ...state, isCheking:false
      }

  case actions.LOGIN_REQUEST:
    return {
      ...state, isCheking:true
    }  

        
       default:
      return {...state};
  }
}
