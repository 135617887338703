import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';
import shapeDark from '@iso/assets/images/shapes/shape-dark.png';

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    return (
      <React.Fragment>
      
     <footer className="footer">
                 <div className="container-fluid">
                        <Row>
                            <div className="home-shape-bottom">
                                <img src={shapeDark} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </Row>
                    </div>
     
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <Link className="logo-footer" to="#">ACINE JOVEM<span className="text-primary"> NOVA ESPERANÇA/PR</span></Link>
                        {/* <p className="mt-4">Contabilizando seu sucesso!</p> */}
                        <ul className="list-unstyled social-icon social mb-0 mt-4">
                            <li className="list-inline-item"><a href="https://www.facebook.com/ACINE-JOVEM-373667249806672" target="_blank" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></a></li>
                            <li className="list-inline-item"><a href="https://www.instagram.com/acinejovem/" target="_blank" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></a></li>
                            {/* <li className="list-inline-item"><a href="https://api.whatsapp.com/send?phone=5544998132524&text=Contato%20Site:" target="_blank" className="rounded"><i className="mdi mdi-whatsapp" title="Whatsapp"></i></a></li> */}
                        </ul>
                    </div>
                    
                    <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        {/* <h4 className="text-light footer-head">ASSOCIAÇÃO</h4> */}
                        <ul className="list-unstyled footer-list mt-4">
                            {/* <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Sobre Nós</Link></li>
                            <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Serviços</Link></li>
                            <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Equipe</Link></li>
                            <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Preço</Link></li>
                            <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Blog</Link></li>
                            <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Login</Link></li> */}
                        </ul>
                    </div>
                    
                    <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Links</h4>
                        <ul className="list-unstyled footer-list mt-4">
                            <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Termos e Serviços</Link></li>
                        </ul>
                    </div>
                    {/* <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Receba nossas notícias</h4>
                        <p className="mt-4">Enviaremos via email todas novidades e notícias.</p>
                        <form>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="foot-subscribe form-group position-relative">
                                        <label>Escreva seu e-mail <span className="text-danger">*</span></label>
                                        <i className="mdi mdi-email ml-3 icons"></i>
                                        <input type="email" name="email" id="emailsubscribe" className="form-control pl-5 rounded" placeholder="Seu E-mail : " required />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <input type="submit" id="submitsubscribe" name="send" className="btn btn-primary w-100" value="Cadastrar" />
                                </div>
                            </div>
                        </form>
                    </div> */}
                </div>
            </div>
        </footer>
        <hr />
        <footer className="footer footer-bar">
            <div className="container text-center">
                <div className="row align-items-center">
                    <div className="col-sm-6">
                        <div className="text-sm-left">
                            <p className="mb-0">©  {new Date().getFullYear()}  ACINE JOVEM. Desenvolvido por <a href="http://www.nfj.com.br">NFJ Sistemas</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        
      </React.Fragment>
    );
  }
}

export default Footer;
