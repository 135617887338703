import antdBr from 'antd/lib/locale-provider/pt_BR';
import brMessages from '../locales/pt_BR.json';


antdBr.DatePicker.lang.dateFormat= "DD/MM/YYYY";
antdBr.DatePicker.lang.locale= "pt-br";
antdBr.DatePicker.lang.shortMonths = 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split('_')
antdBr.DatePicker.lang.shortMonths= 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_')
antdBr.DatePicker.lang.shortWeekDays= 'dom_seg_ter_qua_qui_sex_sáb'.split('_')

const BrLang = {
  messages: {
    ...brMessages,
  },
  antd:  antdBr,
  locale: 'pt-br',
};
export default BrLang;
