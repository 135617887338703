import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Layout Components
import Topbar from './Topbar';
import Footer from './Footer';

// Scroll up button
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";

class Layout extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }
  
  componentDidMount() {
    document.getElementById("pageLoader").style.display = "block";
    setTimeout(function () { document.getElementById("pageLoader").style.display = "none"; }, 1000);
  }

  render() {
    return (
      <React.Fragment>
          <div id="pageLoader">
          <div id="preloader">
            <div id="status">
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
            </div>
        </div>
        </div>
        <Topbar />
        <div style={{marginTop:"60px", clear:"both"}}>
            {this.props.children}
          </div>
        <Footer />
        <div id="bottomIcon">
          <ScrollUpButton ContainerClassName="back-to-top rounded text-center"  />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
