import { combineReducers } from 'redux';
import Auth from './auth/reducer';
import App from './app/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import Usuario from './usuario/reducers';
import User from './user/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';


export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  Usuario,
  User,
  LanguageSwitcher,
});
